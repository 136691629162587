import _objectSpread from "/usr/local/src/wholesale-shop-h5/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, unref as _unref, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-f3b0184e"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 0,
  class: "pay-limit"
};
import { PAY_TYPE_LIST, PAY_DIC } from '@/views/payPage/const';
import { computed, ref, watch, watchEffect } from 'vue';
import paymentSetup from '@/components/setup/paymentSetup';
import { showConfirmDialog, showToast } from 'vant';
import { getPayConfigForSupplier } from '@/api/system';
import QuickPayBankSelect from '@/views/payPage/quickPayMgmt/quickPayBankSelect.vue';
export default {
  __name: 'PayTypeSelect',
  props: {
    modelValue: {
      type: Boolean,
      default: null
    },
    cardBindId: {
      type: [Number, String, null],
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    supplierId: {
      type: [Number, String, null],
      default: null
    }
  },
  emits: ['update:modelValue', 'update:cardBindId', 'update:loading'],
  setup(__props, {
    expose: __expose,
    emit: __emit
  }) {
    const $paymentSetup = paymentSetup();
    const $props = __props;
    const $emit = __emit;
    const payTypeList = ref([]);
    const refreshBankKey = ref(1);
    const loadingValue = computed({
      get() {
        return $props.loading;
      },
      set(val) {
        $emit('update:loading', val);
      }
    });
    const value = computed({
      get() {
        return $props.modelValue;
      },
      set(val) {
        const isWx = $paymentSetup.isWeiXin();
        const isAli = $paymentSetup.isAlipay();
        if (isWx && val === PAY_DIC.ALIPAY_WAP_PAY) return showConfirmDialog({
          message: '微信端不支持支付宝支付，请点击右上角...按钮，在默认浏览器中打开',
          showCancelButton: false,
          confirmButtonText: '好的'
        });else if (isAli && [PAY_DIC.WX_PUB_PAY, PAY_DIC.WX_LITE_PAY].includes(val)) return showConfirmDialog({
          message: '支付宝不支持微信支付，请在微信中打开该页面',
          showCancelButton: false,
          confirmButtonText: '好的'
        });else if (!isWx && !isAli && [PAY_DIC.WX_PUB_PAY, PAY_DIC.WX_LITE_PAY].includes(val)) return showConfirmDialog({
          message: '当前环境不支持微信支付，请在微信中打开该页面',
          showCancelButton: false,
          confirmButtonText: '好的'
        });
        $emit('update:modelValue', val);
      }
    });
    const cardBindIdModelValue = computed({
      get() {
        return $props.cardBindId;
      },
      set(val) {
        $emit('update:cardBindId', val);
      }
    });
    function checkLimit(price) {
      const [pay] = payTypeList.value.filter(e => e['id'] === $props.modelValue);
      if (+price > +(pay === null || pay === void 0 ? void 0 : pay['payLimit'])) {
        showConfirmDialog({
          message: `超过支付限额${+(pay === null || pay === void 0 ? void 0 : pay['payLimit'])}元，请联系商家支付`,
          showCancelButton: false,
          confirmButtonText: '好的'
        });
        return false;
      }
      return true;
    }
    watchEffect(() => {
      const supplierId = $props.supplierId;
      /*
      isDefault: true
      payLimit: 1688
      payMode: "wx_pub"
      payModeName: null
      projectId: 4
      sort: 1
      supplierId: "9"
      */
      if (supplierId) {
        loadingValue.value = true;
        getPayConfigForSupplier({
          supplierId
        }).then(res => {
          var _payTypeList$value, _payTypeList$value$;
          const payCfgList = Array.isArray(res) ? res : [];
          let defVal = null;
          payTypeList.value = [[], ...PAY_TYPE_LIST].reduce((list, dic) => {
            const [payDic] = payCfgList.filter(e => e['payMode'] === dic.id);
            let re = [];
            if (payDic) {
              re = [_objectSpread(_objectSpread({}, dic), {}, {
                name: payDic['payModeName'] || dic.name,
                payLimit: +payDic.payLimit === 0 ? Infinity : +payDic.payLimit,
                sort: payDic.sort
              })];
              if (payDic['isDefault']) defVal = dic.id;
            }
            return [...list, ...re];
          }).sort((a, b) => +a.sort - +b.sort);
          value.value = defVal || ((_payTypeList$value = payTypeList.value) === null || _payTypeList$value === void 0 ? void 0 : (_payTypeList$value$ = _payTypeList$value[0]) === null || _payTypeList$value$ === void 0 ? void 0 : _payTypeList$value$['id']) || null;
          if (!payTypeList.value.length) showConfirmDialog({
            message: `暂无可用的支付方式，请联系商家`,
            showCancelButton: false,
            confirmButtonText: '好的'
          });
          loadingValue.value = false;
        });
      } else {
        payTypeList.value = JSON.parse(JSON.stringify(PAY_TYPE_LIST.filter(e => e.id !== PAY_DIC.QUICK_PAY)));
      }
    });
    __expose({
      checkLimit,
      initQuickPayBankList: () => refreshBankKey.value++,
      checkQuickBankCard() {
        if (value.value === PAY_DIC.QUICK_PAY && !cardBindIdModelValue.value) {
          showToast('请选择支付银行卡');
          return false;
        }
        return true;
      }
    });
    return (_ctx, _cache) => {
      const _component_van_image = _resolveComponent("van-image");
      const _component_van_radio = _resolveComponent("van-radio");
      const _component_van_cell = _resolveComponent("van-cell");
      const _component_van_cell_group = _resolveComponent("van-cell-group");
      const _component_van_radio_group = _resolveComponent("van-radio-group");
      const _component_van_field = _resolveComponent("van-field");
      return _openBlock(), _createElementBlock(_Fragment, null, [payTypeList.value.length ? (_openBlock(), _createBlock(_component_van_field, {
        key: 0,
        label: "支付方式",
        name: "payType",
        class: "pay-type-field",
        "label-width": "4.2em",
        "input-align": "right"
      }, {
        input: _withCtx(() => [_createVNode(_component_van_radio_group, {
          modelValue: value.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => value.value = $event),
          class: "pay-type-radio-group"
        }, {
          default: _withCtx(() => [_createVNode(_component_van_cell_group, {
            border: false
          }, {
            default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(payTypeList.value, type => {
              return _openBlock(), _createBlock(_component_van_cell, {
                class: _normalizeClass({
                  checked: value.value === type.id
                }),
                border: false,
                clickable: !__props.disabled,
                onClick: $event => !__props.disabled && (value.value = type.id)
              }, {
                icon: _withCtx(() => [type.img ? (_openBlock(), _createBlock(_component_van_image, {
                  key: 0,
                  fit: "contain",
                  src: type.img
                }, null, 8, ["src"])) : _createCommentVNode("", true)]),
                title: _withCtx(() => [_createElementVNode("div", null, _toDisplayString(type.name), 1), type.payLimit < Infinity ? (_openBlock(), _createElementBlock("div", _hoisted_1, "限额：￥" + _toDisplayString(type.payLimit), 1)) : _createCommentVNode("", true)]),
                "right-icon": _withCtx(() => [_createVNode(_component_van_radio, {
                  disabled: __props.disabled || loadingValue.value,
                  name: type.id
                }, null, 8, ["disabled", "name"])]),
                _: 2
              }, 1032, ["class", "clickable", "onClick"]);
            }), 256))]),
            _: 1
          })]),
          _: 1
        }, 8, ["modelValue"])]),
        _: 1
      })) : _createCommentVNode("", true), value.value === _unref(PAY_DIC).QUICK_PAY ? (_openBlock(), _createBlock(QuickPayBankSelect, {
        modelValue: cardBindIdModelValue.value,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => cardBindIdModelValue.value = $event),
        loading: loadingValue.value,
        "onUpdate:loading": _cache[2] || (_cache[2] = $event => loadingValue.value = $event),
        key: refreshBankKey.value,
        "supplier-id": __props.supplierId
      }, null, 8, ["modelValue", "loading", "supplier-id"])) : _createCommentVNode("", true)], 64);
    };
  }
};