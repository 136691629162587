import paymentSetup from '@/components/setup/paymentSetup';
// 支付类型
const WX_PUB_PAY = 'wx_pub'; // 微信公众号
const WX_LITE_PAY = 'wx_lite'; // 微信小程序
const ALIPAY_WAP_PAY = 'alipay_wap'; // 支付宝H5支付
const QUICK_PAY = 'quick_pay'; // 快捷支付
const LARGE_PAY = 'large_pay'; // 大额支付

export const PAY_DIC = { WX_PUB_PAY, WX_LITE_PAY, ALIPAY_WAP_PAY, QUICK_PAY, LARGE_PAY };

const $paymentSetup = paymentSetup();
export const DEFAULT_PAY = $paymentSetup.isWeiXin() ? WX_PUB_PAY : ALIPAY_WAP_PAY;
export const PAY_TYPE_LIST = [
  { id: WX_PUB_PAY, name: '微信支付', img: 'img/pay_type_img/wx_pay.svg', payLimit: Infinity, sort: 0 },
  // { id: WX_LITE_PAY, name: '微信支付', img: 'img/pay_type_img/wx_pay.svg', payLimit: Infinity, sort: 0 },
  { id: ALIPAY_WAP_PAY, name: '支付宝支付', img: 'img/pay_type_img/ali_pay.svg', payLimit: Infinity, sort: 0 },
  { id: QUICK_PAY, name: '快捷支付', img: 'img/pay_type_img/quick_pay.svg', payLimit: Infinity, sort: 0 },
  // { id: LARGE_PAY, name: '大额支付', img: '', payLimit: Infinity, sort: 0 },
];
export const PAY_TYPE_NAME_MAP = [{}, ...PAY_TYPE_LIST].reduce((map, e) => ({
  ...map,
  [e.id]: e.name,
}));


export const GET_WX_CODE_FN = 'getWxCode'; // 微信公众号支付页面跳转标识
