import { ref, reactive } from 'vue';

const objectGetter = (obj = {}, keys = '', separator = '.') => {
  let keyArr = keys.split(separator);
  let val = JSON.parse(JSON.stringify(obj));
  for (let i = 0; i < keyArr.length; i++)
    if (typeof val === 'object' && val)
      val = val[keyArr[i]];
    else if (i < keyArr.length)
      return;
  return val;
};

const DEFAULT_PARAMS_NAME = () => ({
  currentPage: 'current',
  pageSize: 'size',
});

const DEFAULT_PAGINATION = () => ({
  total: 0, // 总页数
  currentPage: 1, // 当前页数
  pageSize: 20 // 每页显示多少条
});

const DEFAULT_OPTS = () => ({
  paramsName: {
    ...DEFAULT_PARAMS_NAME(),
  },
  pageSize: DEFAULT_PAGINATION().pageSize,
  params: {},
  getList: () => new Promise(resolve => resolve([])),
  afterLoad: res => {
    return {
      list: objectGetter(res, 'records'),
      total: objectGetter(res, 'total'),
    };
  }
});

export default function (opts = DEFAULT_OPTS()) {
  opts = opts || {};

  const dataList = ref([]);
  const paginationOpts = reactive(DEFAULT_PAGINATION());
  const loading = ref(false);
  const finished = ref(false);
  const refreshing = ref(true);

  const {
    paramsName,
    pageSize,
    params,
    getList,
    afterLoad,
  } = {
    ...DEFAULT_OPTS(),
    ...opts,
  };
  paginationOpts.pageSize = pageSize;

  const {
    currentPage: currentPageName,
    pageSize: pageSizeName,
  } = {
    ...DEFAULT_PARAMS_NAME(),
    ...paramsName,
  };

  async function onLoad(params) {
    // 将 loading 设置为 true，表示处于加载状态
    loading.value = true;

    if (refreshing.value) {
      dataList.value = [];
      paginationOpts.currentPage = 1;
      refreshing.value = false;
    } else {
      paginationOpts.currentPage++;
    }

    let data = await getList({
      [currentPageName]: paginationOpts.currentPage, // 当前页数
      [pageSizeName]: paginationOpts.pageSize // 每页显示多少条
    });
    data = data || {};
    const { list, total } = afterLoad(data);
    let pageList = Array.isArray(list) ? list : [];
    dataList.value = [...dataList.value, ...pageList];
    paginationOpts.total = total;

    loading.value = false;

    if (dataList.value.length >= paginationOpts.total || !pageList.length) {
      finished.value = true;
    }
  }

  function onRefresh() {
    if (loading.value)
      return;

    refreshing.value = true;
    // 清空列表数据
    finished.value = false;
    // 重新加载数据
    return onLoad();
  }

  return {
    dataList,
    loading,
    finished,
    refreshing,
    pagination: paginationOpts,
    onLoad,
    onRefresh,
  };

}
