import ThemeConfig from '@/components/ThemeConfig/index.vue';
import PageContainer from '@/components/PageContainer.vue';
import BeautyPrice from '@/components/BeautyPrice.vue';
import DateRangePicker from '@/components/DateRangePicker/DateRangePicker.vue';
import PagingList from '@/components/PagingList.vue';
import CouponSelector from '@/components/CouponSelector.vue';
import SkuSelector from '@/components/SkuSelector.vue';
import CartEnter from '@/components/CartEnter.vue';
import OverlayLoading from '@/components/OverlayLoading.vue';
import PayTypeSelect from '@/views/payPage/PayTypeSelect.vue';
import VanPullRefreshPagingList from '@/components/VanPullRefreshPagingList.vue';

export default function (app) {
  app.component(ThemeConfig.name, ThemeConfig);
  app.component(PageContainer.name, PageContainer);
  app.component(BeautyPrice.name, BeautyPrice);
  app.component('DateRangePicker', DateRangePicker);
  app.component('PagingList', PagingList);
  app.component('CouponSelector', CouponSelector);
  app.component('SkuSelector', SkuSelector);
  app.component('CartEnter', CartEnter);
  app.component('OverlayLoading', OverlayLoading);
  app.component('PayTypeSelect', PayTypeSelect);
  app.component('VanPullRefreshPagingList', VanPullRefreshPagingList);
}
