import { createRouter, createWebHashHistory } from 'vue-router';
import { GET_TOKEN } from '@/util/storage';

const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    {
      path: '/',
      name: 'boot',
      component: () => import('../views/boot.vue')
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('../views/login.vue')
    },
    {
      path: '/codeLogin',
      name: 'codeLogin',
      component: () => import('../views/codeLogin.vue')
    },
    {
      path: '/registerByStoreId',
      name: 'registerByStoreId',
      component: () => import('../views/registerByStoreId.vue')
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('../views/register.vue')
    },
    {
      path: '/home',
      name: 'home',
      redirect: '/home/home',
      meta: { name: 'tabs', keepAlive: true },
      component: () => import('@/views/homeTabsPage.vue'),
      children: [
        {
          path: '/home/home',
          name: 'homeHome',
          meta: { name: '首页', keepAlive: true },
          component: () => import('@/views/homeHome/homeHome.vue')
        },
        {
          path: '/home/goods',
          name: 'homeGoods',
          meta: { name: '商品', keepAlive: true },
          component: () => import('@/views/homeGoods/homeGoods.vue')
        },
        {
          path: '/home/stock',
          name: 'homeStock',
          meta: { name: '库存', keepAlive: true },
          component: () => import('@/views/homeStock/homeStock.vue')
        },
        {
          path: '/home/order',
          name: 'homeOrder',
          meta: { name: '订单', keepAlive: true },
          component: () => import('@/views/homeOrder/homeOrder.vue')
        },
        {
          path: '/home/my',
          name: 'homeMy',
          component: () => import('@/views/homeMy/my.vue')
        },
      ],
    },
    {
      path: '/applyToOpenStore',
      name: 'applyToOpenStore',
      meta: { name: '升级店铺' },
      component: () => import('@/views/applyToOpenStore.vue')
    },
    {
      path: '/goodsDetail',
      name: 'goodsDetail',
      meta: { name: '商品详情' },
      component: () => import('@/views/goodsDetail.vue')
    },
    {
      path: '/cart',
      name: 'cart',
      meta: { name: '购物车' },
      component: () => import('@/views/cartGroupBrand.vue')
    },
    {
      path: '/applyInvoice',
      name: 'applyInvoice',
      meta: { name: '发票申请' },
      component: () => import('@/views/invoice/applyInvoice.vue')
    },
    {
      path: '/invoiceList',
      name: 'invoiceList',
      meta: { name: '发票列表' },
      component: () => import('@/views/invoice/invoiceList.vue')
    },
    {
      path: '/addInvoice',
      name: 'addInvoice',
      meta: { name: '发票列表' },
      component: () => import('@/views/invoice/addHeading.vue')
    },
    {
      path: '/payment',
      name: 'payment',
      meta: { name: '支付', keepAlive: true },
      component: () => import('@/views/payment/paymentPage.vue')
    },
    {
      path: '/multipleSupplierStock',
      name: 'multipleSupplierStock',
      meta: { name: '多供应商库存提货' },
      component: () => import('@/views/homeStock/multipleSupplierStock.vue')
    },
    {
      path: '/stockCart',
      name: 'stockCart',
      meta: { name: '提货购物车' },
      // component: () => import('@/views/homeStock/stockCart.vue')
      component: () => import('@/views/homeStock/brandStockCart.vue')
    },
    {
      path: '/pickUpStock',
      name: 'pickUpStock',
      meta: { name: '库存提货' },
      component: () => import('@/views/homeStock/pickUpStock.vue')
    },

    {
      path: '/stockHistory',
      name: 'stockHistory',
      meta: { name: '历史库存' },
      component: () => import('@/views/homeStock/stockHistory.vue')
    },
    {
      path: '/obtainPayment',
      name: 'obtainPayment',
      meta: { name: '提货', keepAlive: true },
      component: () => import('@/views/payment/obtainPaymentPage.vue')
    },
    {
      path: '/purchaseObtainPayment',
      name: 'purchaseObtainPayment',
      meta: { name: '采购提货', keepAlive: true },
      component: () => import('@/views/payment/purchaseObtainPaymentPage.vue')
    },
    {
      path: '/operateCenter',
      name: 'operateCenter',
      meta: { name: '运营中心' },
      component: () => import('@/views/operateCenter/index.vue')
    },
    {
      path: '/withdrawal',
      name: 'withdrawal',
      meta: { name: '提现' },
      component: () => import('@/views/withdrawal/index.vue')
    },
    {
      path: '/withdrawalRecord',
      name: 'withdrawalRecord',
      meta: { name: '提现记录' },
      component: () => import('@/views/withdrawal/withdrawalRecord.vue')
    },
    {
      path: '/storeInformation',
      name: 'storeInformation',
      meta: { name: '门店信息' },
      component: () => import('@/views/my/storeInformation.vue')
    },
    {
      path: '/modifyPass',
      name: 'modifyPass',
      meta: { name: '修改密码' },
      component: () => import('@/views/my/modifyPass.vue')
    },
    {
      path: '/businessLicense',
      name: 'businessLicense',
      meta: { name: '营业执照' },
      component: () => import('@/views/my/businessLicense.vue')
    },
    {
      path: '/bankCard',
      name: 'bankCard',
      meta: { name: '银行卡管理' },
      component: () => import('@/views/my/bankCard.vue')
    },
    {
      path: '/goodsAddressList',
      name: 'goodsAddressList',
      meta: { name: '收货地址' },
      component: () => import('@/views/my/goodsAddressList.vue')
    },
    {
      path: '/editAddress',
      name: 'editAddress',
      meta: { name: '收货地址' },
      component: () => import('@/views/my/editAddress.vue')
    },
    {
      path: '/couponList',
      name: 'couponList',
      meta: { name: '优惠券列表' },
      component: () => import('@/views/my/coupon/couponList.vue')
    },
    {
      path: '/couponRecord',
      name: 'couponRecord',
      meta: { name: '优惠券记录' },
      component: () => import('@/views/my/coupon/couponRecord.vue')
    },
    {
      path: '/staffManagementList',
      name: 'staffManagementList',
      meta: { name: '员工管理' },
      component: () => import('@/views/my/staff/staffManagementList.vue')
    },
    {
      path: '/staff',
      name: 'staff',
      meta: { name: '员工管理' },
      component: () => import('@/views/my/staff/staff.vue')
    },
    {
      path: '/agencyLicense',
      name: 'agencyLicense',
      meta: { name: '代办执照' },
      component: () => import('@/views/agencyLicense/index.vue')
    },
    {
      path: '/branchCommission',
      name: 'branchCommission',
      meta: { name: '营销赋能' },
      component: () => import('@/views/branchCommission.vue')
    },
    {
      path: '/studioCommission',
      name: 'studioCommission',
      meta: { name: '工作室' },
      component: () => import('@/views/studioCommission.vue')
    },
    {
      path: '/expressList',
      name: 'expressList',
      meta: { name: '快递公司' },
      component: () => import('@/views/expressList.vue')

    },
    {
      path: '/purchaseOrderDetail',
      name: 'purchaseOrderDetail',
      meta: { name: '采购订单详情' },
      component: () => import('@/views/orderDetail/purchaseOrder.vue')
    },
    {
      path: '/obtainOrderDetail',
      name: 'obtainOrderDetail',
      meta: { name: '提货订单详情' },
      component: () => import('@/views/orderDetail/obtainOrder.vue')
    },
    {
      path: '/testPage',
      name: 'testPage',
      meta: { name: 'testPage' },
      component: () => import('@/views/testPage.vue'),
      children: [
        {
          path: '/testPage/expressList',
          name: 'expressList',
          meta: { name: '快递公司' },
          component: () => import('@/views/expressList.vue')
        },
      ]

    },
    {
      path: '/ApplyForReturnGoods',
      name: 'ApplyForReturnGoods',
      meta: { name: '申请退货' },
      component: () => import('@/views/returnOfGoods/ApplyForReturnGoods.vue')

    },

    {
      path: '/ApplyForReturn',
      name: 'ApplyForReturn',
      meta: { name: '发退货' },
      component: () => import('@/views/returnOfGoods/ApplyForReturn.vue'),
      children: [
        {
          path: '/testPage/expressList',
          name: 'expressList',
          meta: { name: '快递公司' },
          component: () => import('@/views/expressList.vue')
        },
      ]
    },
    {
      path: '/returnOfGoodsDetails',
      name: 'returnOfGoodsDetails',
      meta: { name: '退货详情' },
      component: () => import('@/views/returnOfGoods/returnOfGoodsDetails.vue')
    },
    {
      path: '/returnOrder',
      name: 'returnOrder',
      meta: { name: '退货单' },
      component: () => import('@/views/returnOfGoods/returnOrder.vue')
    },
    {
      path: '/regionalPromotion',
      name: 'regionalPromotion',
      meta: { name: '门店推广' },
      component: () => import('@/views/regionalPromotion.vue')
    },
    {
      path: '/about',
      name: 'about',
      meta: { name: '关于' },
      component: () => import('@/views/about.vue')
    },
    {
      path: '/contractTab',
      name: 'contractTab',
      meta: { name: '我的合同' },
      component: () => import('@/views/my/contractTab.vue')
    },
    {
      path: '/uploadContract',
      name: 'uploadContract',
      meta: { name: '上传合同' },
      component: () => import('@/views/contract/uploadContract.vue')
    },
    {
      path: '/beforeOpenStoreForm',
      name: 'beforeOpenStoreForm',
      meta: { name: '签署合同' },
      component: () => import('@/views/contract/beforeOpenStoreForm.vue')
    },

    {
      path: '/writtenContract',
      name: 'writtenContract',
      meta: { name: '签署合同' },
      component: () => import('@/views/contract/writtenContract.vue')
    },
    {
      path: '/signContractDetail',
      name: 'signContractDetail',
      meta: { name: '详情' },
      component: () => import('@/views/my/signContractDetail.vue')
    },
    {
      path: '/regionalAgentDetail',
      name: 'regionalAgentDetail',
      meta: { name: '区域代理明细' },
      component: () => import('@/views/regionalAgent/detail.vue')
    },
    {
      path: '/regionalAgentSummary',
      name: 'regionalAgentSummary',
      meta: { name: '区域渠道商费用汇总' },
      component: () => import('@/views/regionalAgent/summary.vue')
    },
    {
      path: '/recommendedShop',
      name: 'recommendedShop',
      meta: { name: '中心门店' },
      component: () => import('@/views/my/recommendedShop.vue')
    },
    {
      path: '/stockGoodsAmountDetail',
      name: 'stockGoodsAmountDetail',
      meta: { name: '累计进货详情' },
      component: () => import('@/views/stockGoodsAmountDetail.vue')
    },
    {
      path: '/contributionPointHistory',
      name: 'contributionPointHistory',
      meta: { name: '贡献值记录' },
      component: () => import('@/views/contributionPointHistory.vue')
    },
    {
      path: '/servicePointHistory',
      name: 'servicePointHistory',
      meta: { name: '服务值记录' },
      component: () => import('@/views/servicePointHistory.vue')
    },
    {
      path: '/resaleFlow',
      name: 'resaleFlow',
      meta: { name: '订单记录' },
      component: () => import('@/views/resaleFlow.vue')
    },


    {
      path: '/toPay',
      name: 'toPay',
      meta: { name: 'toPay' },
      component: () => import('@/views/payPage/toPay.vue')
    },
    {
      path: '/pubPay',
      name: 'pubPay',
      meta: { name: 'pubPay' },
      component: () => import('@/views/payPage/pubPay.vue')
    },
    {
      path: '/customerService',
      name: 'customerService',
      meta: { name: '客服' },
      component: () => import('@/views/customerService.vue')
    },
    {
      path: '/accountFlow',
      name: 'accountFlow',
      meta: { name: '账户流水' },
      component: () => import('@/views/accountFlow.vue')
    },
    {
      path: '/storeAreaAgent',
      name: 'storeAreaAgent',
      meta: { name: '区域查询' },
      component: () => import('@/views/storeAreaAgent.vue')
    },
    {
      path: '/payDeposit',
      name: 'payDeposit',
      meta: { name: '支付押金' },
      component: () => import('@/views/payDeposit.vue')
    },
    {
      path: '/storeAuthorize',
      name: 'storeAuthorize',
      meta: { name: '店铺授权出货' },
      component: () => import('@/views/storeAuthorize.vue')
    },
    {
      path: '/quickPayBindBankCard',
      name: 'quickPayBindBankCard',
      component: () => import('@/views/payPage/quickPayMgmt/bindBankCard.vue')
    },
  ]
});

router.beforeEach((to, from, next) => {
  if (!['/login', '/registerByStoreId', '/register', '/codeLogin', '/'].includes(to.path)) {
    if (!GET_TOKEN()) {
      next({ name: 'boot' });
    } else {
      next();
    }
  } else
    next();
});

export default router;
