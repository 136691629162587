const TOKEN_NAME = 'pany_token';
const USER_ID_NAME = 'pany_userId';
const USER_NAME_NAME = 'pany_userName';
const LOGIN_ACCOUNT_NAME = 'pany_accountName';
const LOGIN_PDW_NAME = 'pany_PWDName';
const AUTO_LOGIN_NAME = 'pany_autoLogin';
const IS_LOGOUT = 'pany_isLogout'
const PROJECT_CODE_NAME = 'pany_projectCode'

export const SAVE_PROJECT_CODE = projectCode => {
  window.sessionStorage.setItem(PROJECT_CODE_NAME, projectCode);
}

export const GET_PROJECT_CODE = () => {
  return window.sessionStorage.getItem(PROJECT_CODE_NAME) || '';
}

export const SAVE_TOKEN = token => {
  window.sessionStorage.setItem(TOKEN_NAME, token);
};
export const GET_TOKEN = () => {
  return window.sessionStorage.getItem(TOKEN_NAME);
};

export const SAVE_USER_ID = userId => {
  window.sessionStorage.setItem(USER_ID_NAME, userId);
};
export const GET_USER_ID = () => {
  return window.sessionStorage.getItem(USER_ID_NAME);
};

export const SAVE_USER_NAME = userName => {
  window.sessionStorage.setItem(USER_NAME_NAME, userName);
};
export const GET_USER_NAME = () => {
  return window.sessionStorage.getItem(USER_NAME_NAME);
};

export const SAVE_USER_LOGIN_ACCOUNT = account => {
  window.localStorage.setItem(LOGIN_ACCOUNT_NAME, account);
};
export const GET_USER_LOGIN_ACCOUNT = () => {
  return window.localStorage.getItem(LOGIN_ACCOUNT_NAME);
};

export const SAVE_USER_LOGIN_PDW = PWD => {
  window.localStorage.setItem(LOGIN_PDW_NAME, PWD);
};
export const GET_USER_LOGIN_PDW = () => {
  return window.localStorage.getItem(LOGIN_PDW_NAME);
};

export const SAVE_AUTO_LOGIN = autologin => {
  window.localStorage.setItem(AUTO_LOGIN_NAME, JSON.stringify(autologin));
};
export const GET_AUTO_LOGIN = () => {
  return JSON.parse(window.localStorage.getItem(AUTO_LOGIN_NAME));
};

export const SET_IS_LOGOUT = boolean => {
  window.sessionStorage.setItem(IS_LOGOUT, JSON.stringify(boolean || false));
}

export const GET_IS_LOGOUT = () => {
  return JSON.parse(window.sessionStorage.getItem(IS_LOGOUT)) || false;
}
