import { throttle } from '@/util';
export default {
  name: 'PageContainer',
  props: {
    scrollTop: {
      default: () => 0
    }
  },
  beforeMount() {
    this.handleOnScroll = throttle(this.handleOnScroll, 1000 / 30);
  },
  mounted() {},
  methods: {
    handleOnScroll() {
      this.$emit('update:scrollTop', this.$refs.$pageContainer.scrollTop);
    },
    scrollToTop(top) {
      this.$refs.$pageContainer.scroll(0, top);
    }
  }
};