import request from '@/plugins/axios';

// 获取店铺已绑定的快捷支付银行卡列表，参数：supplierId --供应商id
export function getBindBankCards(params = {}, opts = {}) {
  return request({
    url: `/shop/quickPay/getBindBankCards`,
    method: 'get',
    params,
    ...opts,
  });
}

// 解除绑定
export function quickBuckleUnbind(data = {}, opts = {}) {
  return request({
    url: `/shop/quickPay/quickbuckleUnbind`,
    method: 'post',
    data,
    ...opts,
  });
}

// 获取快捷支付支持的银行列表，
// 参数：
// supplierId --供应商id
// dcType --卡的借贷类型 C:信用卡,D:借记卡
export function getFastPayBindBankList(params = {}, opts = {}) {
  return request({
    url: `/shop/quickPay/getBankList`,
    method: 'get',
    params,
    ...opts,
  });
}

// 获取店主身份信息，参数：storeId - 店铺id
export function getStorekeeperInfo(params = {}, opts = {}) {
  return request({
    url: `/shop/quickPay/getStorekeeperInfo`,
    method: 'get',
    params,
    ...opts,
  });
}

// 快捷绑卡申请
export function quickBuckleApply(data = {}, opts = {}) {
  return request({
    url: `/shop/quickPay/quickbuckleApply`,
    method: 'post',
    data,
    ...opts,
  });
}

// 快捷绑卡确认
export function quickBuckleConfirm(data = {}, opts = {}) {
  return request({
    url: `/shop/quickPay/quickbuckleConfirm`,
    method: 'post',
    data,
    ...opts,
  });
}

// 快捷支付短信预校验
export function quickPaySmsCheck(params = {}, opts = {}) {
  return request({
    url: `/shop/quickPay/getStorekeeperInfo`,
    method: 'get',
    params,
    ...opts,
  });
}

// 快捷支付确认
export function quickPayConfirm(data = {}, opts = {}) {
  return request({
    url: `/shop/quickPay/quickPayConfirm`,
    method: 'post',
    data,
    ...opts,
  });
}
