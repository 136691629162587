const primaryColor = '#212c67';
const priceColor = '#ff0000';
const orangeColor = '#ffa100';

export default {
  primaryColor,
  warningColor: orangeColor,
  priceColor,
  orange: orangeColor,


  buttonPrimaryBorderColor: primaryColor,
  buttonPrimaryBackgroundColor: primaryColor,
  buttonPrimaryBackground: primaryColor,
  buttonWarningBackground: orangeColor,
  buttonWarningBorderColor: orangeColor,

  buttonDefaultHeight: '35.5px',
  buttonNormalFontSize: '14.5px',

  navBarBackground: primaryColor,
  navBarIconColor: '#fff',
  navBarTextColor: '#fff',
  navBarTitleTextColor: '#fff',

  tabsBottomBarColor: '#62a3f3',
  tabActiveTextColor: '#333',
  tabTextColor: '#999',
  tabFontSize: '0.039rem',
  tabsLineHeight: '33px',
  tabsBottomBarHeight: '0.0106rem',

  dialogConfirmButtonTextColor: primaryColor,

  pickerConfirmActionColor: primaryColor,

  tabbarItemTextColor: '#999',
  tabbarItemActiveColor: '#e0633e',

  cascaderActiveColor: primaryColor,

  gridItemTextFontSize: '.034rem',

  tagPrimaryColor: primaryColor,

  fieldDisabledTextColor: '#666',
  fieldInputDisabledTextColor: '#333',
  uploaderDisabledOpacity: '.9',

  checkboxCheckedIconColor: primaryColor,

  radioCheckedIconColor: primaryColor,

  stepperButtonRoundThemeColor: primaryColor,
  stepperButtonIconColor: primaryColor,
  stepperInputHeight: '16px',
  stepperRadius: 0,
  stepperBackground: '#fff',

  switchOnBackgroundColor: primaryColor,

  dropdownMenuTitleActiveTextColor: primaryColor,
  dropdownMenuOptionActiveColor: primaryColor,

  noticeBarHeight: '40px',
};
