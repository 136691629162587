import _objectSpread from "/usr/local/src/wholesale-shop-h5/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-7b0220e0"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 0,
  class: "obtain-coupon-title"
};
const _hoisted_2 = {
  class: "obtain-coupon-list-container"
};
const _hoisted_3 = ["onClick"];
const _hoisted_4 = ["data-name"];
const _hoisted_5 = {
  style: {
    "margin": "1.3em"
  }
};
import { computed, ref } from 'vue';
import { closeToast, showLoadingToast } from 'vant';
export default {
  __name: 'CouponSelector',
  props: {
    show: {
      type: Boolean,
      default: () => false
    },
    zIndex: {
      type: [Number, String],
      default: () => 2000
    },
    getList: {
      type: [Function, Array],
      default: () => []
    },
    initValue: {
      type: [Number, String, null],
      default: () => null
    },
    onConfirm: {
      type: [null, Function],
      default: () => null
    }
  },
  emits: ['update:show', 'confirm'],
  setup(__props, {
    emit: __emit
  }) {
    const $props = __props;
    const $emit = __emit;
    const dataList = ref([]);
    let checkedIndex = ref(-1);
    const loading = ref(true);
    const selectorShow = computed({
      get() {
        const {
          show
        } = $props;
        if (show) {
          init();
        }
        return show;
      },
      set(val) {
        $emit('update:show', val);
      }
    });
    async function init() {
      loading.value = true;
      const {
        getList,
        initValue
      } = $props;
      let list = [];
      if (Array.isArray(getList)) {
        list = getList;
      } else if (typeof getList === 'function') {
        dataList.value = [];
        showLoadingToast({
          duration: 0,
          forbidClick: true
        });
        await getList().then(res => {
          closeToast();
          list = Array.isArray(res) ? res : [];
        });
      }
      if (initValue) checkedIndex.value = dataList.value.findIndex(e => e['id'] == initValue);else checkedIndex.value = -1;
      loading.value = false;
      dataList.value = list;
    }

    // type 优惠方式 divide.折扣 sub.减金额
    function isDivide(coupon) {
      return coupon.type === 'divide';
    }
    function isSub(coupon) {
      return coupon.type === 'sub';
    }
    function useSubValText(coupon) {
      return isSub(coupon) ? coupon.useSub : +(coupon.useSub * 10).toFixed(2);
    }
    function check(coupon) {
      checkedIndex.value = dataList.value.findIndex(e => e.id === coupon.id);
      onConfirm();
    }
    function unCheck() {
      checkedIndex.value = -1;
      onConfirm();
    }
    let __time = null;
    function onConfirm() {
      if (__time && Date.now() - __time < 1000) return;
      __time = Date.now();
      const data = _objectSpread({}, dataList.value[checkedIndex.value]);
      $emit('confirm', data);
      typeof $props.onConfirm === 'function' && $props.onConfirm(data);
      selectorShow.value = false;
    }
    return (_ctx, _cache) => {
      const _component_van_icon = _resolveComponent("van-icon");
      const _component_van_empty = _resolveComponent("van-empty");
      const _component_van_button = _resolveComponent("van-button");
      const _component_van_popup = _resolveComponent("van-popup");
      return _openBlock(), _createBlock(_component_van_popup, {
        show: selectorShow.value,
        "onUpdate:show": _cache[0] || (_cache[0] = $event => selectorShow.value = $event),
        position: "bottom",
        closeable: "",
        round: "",
        duration: "0.2",
        "z-index": __props.zIndex
      }, {
        default: _withCtx(() => [dataList.value.length ? (_openBlock(), _createElementBlock("div", _hoisted_1, " 请选择优惠券，共 " + _toDisplayString(dataList.value.length) + " 种可用 ", 1)) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(dataList.value, (coupon, index) => {
          return _openBlock(), _createElementBlock("div", {
            key: coupon.id,
            class: "coupon-container",
            onClick: $event => check(coupon)
          }, [_createElementVNode("header", null, [_createElementVNode("div", {
            "data-name": isSub(coupon) ? '元' : '折'
          }, _toDisplayString(useSubValText(coupon)), 9, _hoisted_4), _createElementVNode("p", null, "满 " + _toDisplayString(coupon.useFull) + " 元可用", 1), _createElementVNode("span", null, "共" + _toDisplayString(coupon.num || 1) + "张", 1)]), _createElementVNode("section", null, _toDisplayString(coupon.couponTitle), 1), _createVNode(_component_van_icon, {
            color: _unref(checkedIndex) === index ? '#fa4126' : '#ccc',
            name: _unref(checkedIndex) === index ? 'passed' : 'circle'
          }, null, 8, ["color", "name"])], 8, _hoisted_3);
        }), 128)), !loading.value && !dataList.value.length ? (_openBlock(), _createBlock(_component_van_empty, {
          key: 0,
          description: "暂无可用优惠券"
        })) : _createCommentVNode("", true)]), _createElementVNode("div", _hoisted_5, [_createVNode(_component_van_button, {
          onClick: unCheck,
          round: "",
          block: "",
          type: "primary"
        }, {
          default: _withCtx(() => [_createTextVNode(" 不使用优惠券 ")]),
          _: 1
        })])]),
        _: 1
      }, 8, ["show", "z-index"]);
    };
  }
};