import request from '@/plugins/axios';

// 购物车商品数量修改
export function addGoodsCart(data = {}, opts = {}) {
  return request({
    url: `/shop/goodsCart/add`,
    method: 'put',
    data,
    ...opts,
  });
}

// 增加购物车商品数量
export function saveGoodsCart(data = {}, opts = {}) {
  return request({
    url: `/shop/goodsCart/save`,
    method: 'put',
    data,
    ...opts,
  });
}

// 我的购物车列表
export function getGoodsCartList(params = {}, opts = {}) {
  return request({
    url: `/shop/goodsCart/listCart`,
    method: 'get',
    params,
    ...opts,
  });
}

// 获取购物车商品总数
export function getGoodsCartCount(params = {}, opts = {}) {
  return request({
    url: `/shop/goodsCart/count`,
    method: 'get',
    params,
    ...opts,
  });
}

// 采购创建订单后刷新购物车数据
export function clearCart(data = {}, opts = {}) {
  return request({
    url: `/shop/goodsCart/delByGoodIds`,
    method: 'post',
    data,
    ...opts,
  });
}
