import {
  ConfigProvider,
  NavBar,
  Button,
  Icon, Image,
  Tag,
  List, PullRefresh,
  Empty,
  Tabbar, TabbarItem,
  Tabs, Tab,
  Popup, Overlay,
  ActionSheet,
  Picker,
  Cascader,
  DatePicker,
  Field, Search,
  CellGroup,
  RadioGroup, Radio,
  Form,
  Stepper,
  Sidebar, SidebarItem,
  Swipe, SwipeItem,
  DropdownMenu, DropdownItem,
  NoticeBar,
  Loading,
} from 'vant';

export default function (app) {
  app.use(ConfigProvider);
  app.use(NavBar);
  app.use(Button);
  app.use(Icon);
  app.use(Image);
  app.use(Tag);
  app.use(List);
  app.use(PullRefresh);
  app.use(Empty);
  app.use(Tabbar);
  app.use(TabbarItem);
  app.use(Tabs);
  app.use(Tab);
  app.use(Popup);
  app.use(Overlay);
  app.use(ActionSheet);
  app.use(DatePicker);
  app.use(Picker);
  app.use(Cascader);
  app.use(Field);
  app.use(Search);
  app.use(CellGroup);
  app.use(RadioGroup);
  app.use(Radio);
  app.use(Form);
  app.use(Stepper);
  app.use(Sidebar);
  app.use(SidebarItem);
  app.use(Swipe);
  app.use(SwipeItem);
  app.use(Loading);
  app.use(NoticeBar);
  app.use(DropdownMenu);
  app.use(DropdownItem);
}
