import "core-js/modules/es.array.push.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { ref } from 'vue';
import { getGoodsCartCount } from '@/api/cart';
export default {
  __name: 'CartEnter',
  props: {
    type: {
      type: String,
      default: () => 'fixed' // fixed inline
    },
    immediateCheck: {
      type: Boolean,
      default: () => true
    }
  },
  setup(__props, {
    expose: __expose
  }) {
    const $props = __props;
    const total = ref('');
    function init() {
      getGoodsCartCount().then(res => {
        total.value = +res ? res : '';
      });
    }
    $props.immediateCheck && init();
    __expose({
      init
    });
    return (_ctx, _cache) => {
      const _component_van_icon = _resolveComponent("van-icon");
      return _openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["cart-enter-container", [__props.type, total.value ? 'has-count' : '']]),
        onClick: _cache[0] || (_cache[0] = $event => _ctx.$router.push('/cart'))
      }, [_createElementVNode("div", null, [_createVNode(_component_van_icon, {
        name: "cart-o",
        badge: total.value
      }, null, 8, ["badge"])])], 2);
    };
  }
};