import axios from 'axios';
import qs from 'qs';
import { showConfirmDialog, showToast } from 'vant';
import { objectGetter, serialize } from '@/util';
import { GET_TOKEN, SAVE_AUTO_LOGIN, SAVE_TOKEN, SAVE_USER_ID, SAVE_USER_NAME } from '@/util/storage';
import { VUE_APP_API_URL } from '@/config';

// 响应成功对象参数名称设置
const RESULT_CODE = 'code';
const RESULT_DATA = 'data';
const RESULT_MESSAGE = 'msg';
const SUCCESS_CODE = 0; // 请求成功code
const NOT_LOGGED_IN_CODE = 401; // 未登录、登录失效code

// 响应异常提示方法配置
// const errorTip = message => showToast(message);
const errorTip = message => showConfirmDialog({
  message,
  showCancelButton: false,
  confirmButtonText: '好的',
})

axios.defaults.baseURL = VUE_APP_API_URL;

let config = {
  timeout: 30 * 1000, // Timeout
  withCredentials: true, // Check cross-site Access-Control
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  function (config) {
    let { notNeedToken = false } = config.headers || {};
    let loginToken = 'Bearer ' + GET_TOKEN();
    let noLoginToken = 'Basic cGFueTpwYW55';
    config.headers['Authorization'] = notNeedToken ? noLoginToken : loginToken;

    // headers中配置serialize为true开启序列化
    if (config.methods === 'post' && config.headers.serialize) {
      config.data = serialize(config.data);
      delete config.data.serialize;
    }

    // 处理get 请求的数组 springmvc 可以处理
    if (config.method === 'get') {
      config.paramsSerializer = function (params) {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      };
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function (response) {
    let getAllResponse = objectGetter(response, 'config.headers.getAllResponse');
    if (getAllResponse) {
      return response;
    }

    return formatRes(response);
  },
  function (error) {
    let response = error.response;
    let noErrorTip = objectGetter(response, 'config.headers.noErrorTip');

    if (!response) {
      !noErrorTip && errorTip(catchErrorTip(error));
      return Promise.resolve(null);
    }

    let getAllResponse = objectGetter(response, 'config.headers.getAllResponse');
    if (getAllResponse) {
      return Promise.resolve(response);
    }

    return Promise.resolve(formatRes(response));
  }
);

const formatRes = (response) => {
  let noErrorTip = objectGetter(response, 'config.headers.noErrorTip');
  let result = response['data'] || {},
    data = result[RESULT_DATA],
    code = result[RESULT_CODE],
    message = result[RESULT_MESSAGE];
  if (code === NOT_LOGGED_IN_CODE) {
    showConfirmDialog({
      message: `您的登录状态已失效，请重新登录`,
      showCancelButton: false,
      confirmButtonText: '重新登录',
    }).then(() => {
      SAVE_TOKEN('');
      SAVE_USER_NAME('');
      SAVE_USER_ID('');
      SAVE_AUTO_LOGIN(false);
      window.location.reload();
    });

    return data;
  }

  if (code !== SUCCESS_CODE && !noErrorTip) {
    errorTip(message || '请求失败.');
  }

  return data;
};

const catchErrorTip = error => {
  if (typeof error === 'string') {
    return error;
  }
  let { message } = error;
  if (message === 'Network Error') {
    message = '网络连接异常';
  } else if (message.includes('timeout')) {
    message = '请求超时';
  } else {
    message = '系统发生未知错误，请联系管理员';
  }
  return message;
};

export default _axios;
