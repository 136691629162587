import request from '@/plugins/axios';
import { encryption } from '@/util';

// 用户登录
const scope = 'server';

export const loginByUsername = ({ username = '', password = '' } = {}) => {
  const grant_type = 'password';

  const user = encryption({
    data: {
      username, password,
      randomStr: '',
    },
    key: 'hello,panying123',
    param: ['password']
  });

  return request({
    url: '/auth/oauth/token',
    headers: {
      notNeedToken: true,
      noErrorTip: true,
      getAllResponse: true,
    },
    method: 'post',
    params: {
      username: user.username,
      password: user.password,
      randomStr: user.randomStr,
      grant_type,
      scope,
    }
  });
};

export function codeLogin(code = '') {
  return request({
    url: '/auth/oauth/token',
    headers: {
      notNeedToken: true,
      noErrorTip: true,
      getAllResponse: true,
    },
    method: 'post',
    params: {
      code,
      grant_type: 'code',
      scope: 'server',
    }
  });
}

// 字典
export function getDictByType(dicType) {
  return request({
    url: `/admin/dict/type/${ dicType }`,
    method: 'get'
  });
}


export function uploadImage(file, subPath = '/store/Image') {
  const params = new FormData();
  params.append('file', file);
  return request({
    url: `/upload${ subPath }`,
    method: 'post',
    headers: { 'Content-Type': 'multipart/form-data', getAllResponse: true },
    data: params,
  });
}

// /expressInfo/list
export function getExpressInfoList() {
  return request({
    url: `/shop/expressInfo/list`,
    method: 'get'
  });
}

// 查询某店铺信息
export function getStoreInfoByStoreId(params = {}, opts = {}) {
  return request({
    url: `/shop/api/storeInfo/getStoreInfo/${ params.storeId }`,
    headers: {
      notNeedToken: true,
    },
    method: 'get',
    params,
    ...opts,
  });
}

// 获取区域列表
export function getAreaList(params = {}, opts = {}) {
  return request({
    url: `/shop/api/storeInfo/getAreaList`,
    headers: {
      notNeedToken: true,
    },
    method: 'get',
    params,
    ...opts,
  });
}

// 注册店铺
export function registerStore(data = {}, opts = {}) {
  return request({
    url: '/shop/api/storeInfo/register',
    headers: {
      notNeedToken: true,
    },
    method: 'post',
    data,
    ...opts,
  });
}

// 获取微信公众号支付参数
/*
 @param code 微信授权码
 @param orderNo 订单号
 @param projectId 项目id
*/
export function getWxPubPayInfo(params = {}, opts = {}) {
  return request({
    url: `/shop/callback/weixin-pay/wxPub`,
    method: 'get',
    params,
    ...opts,
  });
}

export function querySystemConfig(params) {
  return request({
    url: '/shop/systemConfig/query',
    method: 'get',
    params
  });
}

export function queryProjectCompanySeal() {
  return querySystemConfig({
    brandId: 0,
    key: 'project_company_seal'
  });
}

// 开店是否需要交押金判断
export function storeDepositCheck(params, opts = {}) {
  return request({
    url: '/shop/storeDeposit/check',
    method: 'get',
    params,
    ...opts,
  });
}

// 供应商支付方式列表
export function getPayConfigForSupplier(params, opts = {}) {
  return request({
    url: `/shop/payConfig/getPayConfigForSupplier/${ params.supplierId }`,
    method: 'get',
    params,
    ...opts,
  });
}

// 供应商支付方式列表
export function getSupplierInfo(params, opts = {}) {
  return request({
    url: `/shop/supplierInfo/${ params.supplierId }`,
    method: 'get',
    params,
    ...opts,
  });
}
