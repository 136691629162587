import * as CryptoJS from 'crypto-js';
import { uploadImage } from '@/api/system';
import { showConfirmDialog } from 'vant';
import compressor from '@/util/compressorJs';

/**
 *加密处理
 */
export const encryption = (params) => {
  let {
    data,
    type,
    param,
    key
  } = params;
  const result = JSON.parse(JSON.stringify(data));
  if (type === 'Base64') {
    param.forEach(ele => {
      result[ele] = btoa(result[ele]);
    });
  } else {
    param.forEach(ele => {
      var data = result[ele];
      key = CryptoJS.enc.Latin1.parse(key);
      var iv = key;
      // 加密
      var encrypted = CryptoJS.AES.encrypt(
        data,
        key, {
          iv: iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.ZeroPadding
        });
      result[ele] = encrypted.toString();
    });
  }
  return result;
};

// 表单序列化
export const serialize = data => {
  let list = [];
  Object.keys(data).forEach(ele => {
    list.push(`${ ele }=${ data[ele] }`);
  });
  return list.join('&');
};

// 获取url中参数对象
export function getLocationSearch(locationSearch) {
  let p = {};
  locationSearch = locationSearch || window.location.search;
  let search = locationSearch.split('?')[1] || '';
  search.split('&').forEach(e => {
    let [k, v] = e.split('=');
    p[k] = v;
  });
  return p;
}

// 安全获取对象的值
export const objectGetter = (obj = {}, keys = '', separator = '.') => {
  let keyArr = keys.split(separator);
  let val = JSON.parse(JSON.stringify(obj));
  for (let i = 0; i < keyArr.length; i++)
    if (typeof val === 'object' && val)
      val = val[keyArr[i]];
    else if (i < keyArr.length)
      return;
  return val;
};

export const throttle = (fn, delay, deBounce) => {
  delay = delay || 1000 / 60;
  let now, lastExec, timer, context, args;
  let execute = function () {
    fn.apply(context, args || []);
    lastExec = now;
  };
  return function () {
    context = this;
    if (deBounce) {
      clearTimeout(timer);
      timer = setTimeout(execute, delay);
      return;
    }
    args = Array.prototype.slice.apply(arguments);
    now = new Date().getTime();
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }
    if (lastExec) {
      let diff = delay - (now - lastExec);
      if (diff < 0)
        execute();
      else
        timer = setTimeout(execute, diff);
    } else
      execute();
  };
};

export const objListToMap = (list, opts) => {
  const {
    id = 'id',
    name = 'name',
  } = opts || {};

  const arr = Array.isArray(list) ? list : [];
  return [{}, ...arr].reduce((map, e) => ({
    ...map,
    [e[id]]: name ? e[name] : e,
  }));
};

export const toFixed2 = number => {
  number = +number || 0;
  number = +number.toFixed(2);
  number = number === 0 ? 0 : number;
  return number;
};

export function base64ToBlob(base64) {
  let arr = base64.split(','), mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
}

export async function uploadImgByBase64ImgStr(Base64ImgStr, subPath = '/store/Image') {
  let blob = base64ToBlob(Base64ImgStr);
  const fileName = `${ Date.now() }.${ blob.type.replace('image/', '') }`;
  blob.lastModifiedDate = new Date();
  blob.name = fileName;
  let file = new File([blob], fileName, { type: blob.type });
  file = await compressor(file);
  return new Promise(resolve => {
    uploadImage(file, subPath).then(res => {
      const {
        status,
        data,
        statusText,
      } = res || {};
      if (status === 200 && data && data.url) {
        resolve(data.url);
      } else {
        const { msg } = data || {};
        showConfirmDialog({
          message: msg || statusText || '请求失败.',
          showCancelButton: false,
          confirmButtonText: '好的',
        }).then();
        resolve();
      }
    });
  });
}

/**
 * @description 手机号脱敏
 * @param {string} phone 手机号
 * @return {string} 123****7890
 * @example phoneDesensitization('12345678901') // 123****7890
 */
export const phoneDesensitization = phone => {
  if ([undefined, null].includes(phone))
    return phone;
  return (phone + '').replace(/(\d{3})\d{4}(\d{4})/, '$1****$2');
};

/**
 * @description 身份证号脱敏
 * @param {string} idCard 身份证号
 * @return {string} 123****7890
 * @example idCardDesensitization('123456789123456789') // 1234****6789
 */
export const idCardDesensitization = idCard => {
  if ([undefined, null].includes(idCard))
    return idCard;
  return (idCard + '').replace(/^(.{6})(?:\d+)(.{4})$/, '$1******$2');
};

/**
 * @description 姓名脱敏
 * @param {string} name 姓名
 */
export const nameDesensitization = name => {
  if ([undefined, null].includes(name))
    return name;
  return (name + '').replace(/^(.).*(.)$/, (_, $1, $2) => (name.length > 2 ? `${ $1 }**` : `*${ $2 }`));
};

/**
 * @description 银行卡号脱敏
 * @param {string} bankNo 银行卡号
 */
export const banCardNoDesensitization = banCardNo => {
  if ([undefined, null].includes(banCardNo))
    return banCardNo;
  return (banCardNo + '').replace(/^(.{4})(?:\d+)(.{4})$/, '$1 **** **** $2');
};
